import React, { useMemo } from "react";
import "@hassanmojab/react-modern-calendar-datepicker/lib/DatePicker.css";
import {
  Calendar,
  DayRange as CalendarDayRange,
} from "@hassanmojab/react-modern-calendar-datepicker";
import { useTranslation } from "react-i18next";

type Props = {
  selectedDayRange: CalendarDayRange;
  setSelectedDayRange: Function;
};

const App: React.FC<Props> = ({ selectedDayRange, setSelectedDayRange }) => {
  const { i18n } = useTranslation();

  const myCustomLocale = useMemo(() => {
    return i18n.language === "en"
      ? {
          // months list by order
          months: [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December",
          ],

          // week days by order
          weekDays: [
            {
              name: "Sunday", // used for accessibility
              short: "S", // displayed at the top of days' rows
              isWeekend: true, // is it a formal weekend or not?
            },
            {
              name: "Monday",
              short: "M",
            },
            {
              name: "Tuesday",
              short: "T",
            },
            {
              name: "Wednesday",
              short: "W",
            },
            {
              name: "Thursday",
              short: "T",
            },
            {
              name: "Friday",
              short: "F",
            },
            {
              name: "Saturday",
              short: "S",
              isWeekend: true,
            },
          ],

          // just play around with this number between 0 and 6
          weekStartingIndex: 0,

          // return a { year: number, month: number, day: number } object
          getToday(gregorainTodayObject: any) {
            return gregorainTodayObject;
          },

          // return a native JavaScript date here
          toNativeDate(date: any) {
            return new Date(date.year, date.month - 1, date.day);
          },

          // return a number for date's month length
          getMonthLength(date: any) {
            return new Date(date.year, date.month, 0).getDate();
          },

          // return a transformed digit to your locale
          transformDigit(digit: any) {
            return digit;
          },

          // texts in the date picker
          nextMonth: "Next Month",
          previousMonth: "Previous Month",
          openMonthSelector: "Open Month Selector",
          openYearSelector: "Open Year Selector",
          closeMonthSelector: "Close Month Selector",
          closeYearSelector: "Close Year Selector",
          defaultPlaceholder: "Select...",

          // for input range value
          from: "from",
          to: "to",

          // used for input value when multi dates are selected
          digitSeparator: ",",

          // if your provide -2 for example, year will be 2 digited
          yearLetterSkip: 0,

          // is your language rtl or ltr?
          isRtl: false,
        }
      : {
          // months list by order (in Hebrew)
          months: [
            "ינואר", // January
            "פברואר", // February
            "מרץ", // March
            "אפריל", // April
            "מאי", // May
            "יוני", // June
            "יולי", // July
            "אוגוסט", // August
            "ספטמבר", // September
            "אוקטובר", // October
            "נובמבר", // November
            "דצמבר", // December
          ],

          // week days by order (in Hebrew)
          weekDays: [
            {
              name: "ראשון", // Sunday
              short: "א", // displayed at the top of days' rows
              isWeekend: true, // is it a formal weekend or not?
            },
            {
              name: "שני", // Monday
              short: "ב",
            },
            {
              name: "שלישי", // Tuesday
              short: "ג",
            },
            {
              name: "רביעי", // Wednesday
              short: "ד",
            },
            {
              name: "חמישי", // Thursday
              short: "ה",
            },
            {
              name: "שישי", // Friday
              short: "ו",
            },
            {
              name: "שבת", // Saturday
              short: "ש",
              isWeekend: true, // it's considered a weekend
            },
          ],

          // week starting index (Sunday as 0)
          weekStartingIndex: 0, // Israel uses Sunday as the start of the week.

          // return a { year: number, month: number, day: number } object
          getToday(gregorianTodayObject: any) {
            return gregorianTodayObject; // You can leave this as is for a standard date object
          },

          // return a native JavaScript date here (the month is zero-indexed in JavaScript Date)
          toNativeDate(date: any) {
            return new Date(date.year, date.month - 1, date.day); // Adjust for zero-indexed months
          },

          // return a number for the date's month length
          getMonthLength(date: any) {
            return new Date(date.year, date.month, 0).getDate(); // Get the number of days in the month
          },

          // transform digit to your locale (if needed, but not necessary for Hebrew)
          transformDigit(digit: any) {
            return digit; // Hebrew uses standard Arabic numerals, no transformation needed
          },

          // texts in the date picker (in Hebrew)
          nextMonth: "חודש הבא", // Next Month
          previousMonth: "חודש קודם", // Previous Month
          openMonthSelector: "פתח בורר חודש", // Open Month Selector
          openYearSelector: "פתח בורר שנה", // Open Year Selector
          closeMonthSelector: "סגור בורר חודש", // Close Month Selector
          closeYearSelector: "סגור בורר שנה", // Close Year Selector
          defaultPlaceholder: "בחר...", // Select...

          // for input range value (in Hebrew)
          from: "מ", // from
          to: "עד", // to

          // used for input value when multiple dates are selected
          digitSeparator: ",", // Separator for multiple dates (comma)

          // year digit length (keep as is if you want to use 4-digit years)
          yearLetterSkip: 0,

          // is the language RTL or LTR? (Hebrew is RTL)
          isRtl: true,
        };
  }, [i18n.language]);

  return (
    <div className="w-full h-auto pt-2 flex items-center justify-center">
      <Calendar
        calendarClassName="custom-calendar"
        value={selectedDayRange}
        onChange={(value: CalendarDayRange) => setSelectedDayRange(value)} // Make sure value matches the type from the library
        colorPrimary="#161616"
        colorPrimaryLight="rgba(239, 239, 239, 1)"
        shouldHighlightWeekends
        locale={myCustomLocale}
      />
    </div>
  );
};

export default App;
