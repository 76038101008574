import { routes } from "../../constants/routes";
import { api } from "../axiosInstance";

export const fetchUsers = async (
  page: number,
  limit: number,
  debouncedSearch: string,
  selectedTrainerId: number | string | null = null
) => {
  const response = await api.get(
    `${
      routes.USERS
    }?page=${page}&limit=${limit}&sortKey=age&sortDirection=ASC&search=${debouncedSearch}&trainer_id=${
      selectedTrainerId !== null ? Number(selectedTrainerId) : ""
    }`
  );
  return response.data;
};

export const deleteUser = async (userId: any) => {
  const response = await api.delete(`${routes.USERS}/${Number(userId)}`);
  return response.data;
};

export const handleClientAdd = async ({ client, trainer_id }: any) => {
  const { created_by, nutrition_plan, ...filteredClient } = client;
  // const {fname, lname, phone_number, birthdate, current_weight} =
  //   filteredClient;
  // if (!fname || !lname || !phone_number || !birthdate || !current_weight) {
  //   return alert('Please fill all required fields');
  // }
  const response = await api.post("/users", {
    users: [{ ...filteredClient }],
    trainer_id: trainer_id ? Number(trainer_id) : null,
  });
  return response.data;
};

export const handleClientEdit = async ({ userId, client }: any) => {
  const { created_by, nutrition_plan, ...filteredClient } = client;
  const response = await api.put(`/users/${Number(userId)}`, {
    ...filteredClient,
  });
  return response.data;
};

export const fetchClient = async (userId: any) => {
  const response = await api.get(`/users/${userId}`);
  return response.data;
};

export const handlePlanAssociation = async ({
  userId,
  selectedPlanId,
}: {
  userId: any;
  selectedPlanId: any;
}) => {
  const response = await api.put(`${routes.USERS}/${userId}`, {
    nutrition_plan: selectedPlanId,
  });
  return response.data;
};

export const fetchUserStats = async (
  userId: number,
  startDate: string,
  endDate: string
) => {
  const response = await api.get(
    `/stats?userId=${userId}&startDate=${startDate}&endDate=${endDate}`
  );
  return response.data;
};
