import React, {useState} from 'react';
import Header from '../../components/Header';
import NutrisionistTable from './nutrisionistTable';
import {useTranslation} from 'react-i18next';
import {GridColDef, GridRenderCellParams} from '@mui/x-data-grid';
import {Checkbox, ListItemText, Menu, MenuItem} from '@mui/material';
import {useMutation, useQuery, useQueryClient} from 'react-query';
import * as XLSX from 'xlsx';
import {useNavigate} from 'react-router-dom';
import {
  handlePlanCopy,
  handlePlanDelete,
  handleFetchPlans,
} from '../../services/plans';
import Loader from '../../components/Loader';
import {
  ImportSvg,
  ExportSvg,
  ColSelectSvg,
  SearchSvg,
  BasketSvg,
  EditSvg,
  CopySvg,
} from '../../assets';
import {handleExport, downloadBlob} from '../../services/importAndExport';
import {routes} from '../../constants/routes';
import {useDebounce} from '../../services/debounce';
import IconInput from '../../components/IconInput';
import TrainerSelector from '../../components/TrainerSelector';

type Props = {};

const isValidData = (data: any) => {
  const keys = Object.keys(data[0]);
  if (
    keys.length > 0 &&
    keys.includes('name') &&
    keys.includes('max_age') &&
    keys.includes('min_age') &&
    keys.includes('plan_tags') &&
    keys.includes('plan_notes') &&
    keys.includes('plan_gender')
  ) {
    return true;
  } else {
    return false;
  }
};

function Index({}: Props) {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [importedArticles, setImportedArticles] = useState();
  const [delPlanId, setDelPlanId] = useState();
  const [selectedTrainerId, setSelectedTrainerId] = useState<any>(null);

  const columns: GridColDef[] = [
    {
      field: 'plan_name',
      headerName: t('planname'),
      width: 150,
      minWidth: 150,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'plan_tags',
      headerName: t('plantags'),
      width: 200,
      minWidth: 200,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params: GridRenderCellParams<any>) => (
        <div className="h-full w-full flex justify-center items-center gap-2 flex-wrap py-2">
          {params.value?.map((item: string) => (
            <div
              key={item}
              className="text-center border border-border-color rounded-full">
              <p className="text-xs font-[400] py-1 Plan Assigned px-10">
                {item}
              </p>
            </div>
          ))}
        </div>
      ),
    },
    {
      field: 'plan_gender',
      headerName: t('plangender'),
      width: 100,
      minWidth: 100,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params: GridRenderCellParams<any>) => (
        <div className="h-full w-full flex justify-center items-center gap-2 flex-wrap py-2">
          {params?.value?.map(
            (item: string, index: number, array: string[]) => (
              <p key={item} className="text-xs font-[400]">
                {t(item)}
                {index < array.length - 1 && ', '}
              </p>
            ),
          )}
        </div>
      ),
    },
    {
      field: 'plan_ages',
      headerName: t('planages'),
      width: 100,
      minWidth: 100,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params: GridRenderCellParams<any>) => {
        const {min, max} = params.value[0] || {};
        const displayValue = min && max ? `${min}-${max}` : min || max || '';

        return (
          <div className="h-full w-full flex justify-center items-center">
            <p className="text-dark-text text-[16px]">{displayValue}</p>
          </div>
        );
      },
    },
    {
      field: 'col5',
      headerName: t('actions'),
      width: 200,
      minWidth: 200,
      flex: 1,
      sortable: false,
      disableColumnMenu: true,
      headerAlign: 'right',
      renderCell: (params: GridRenderCellParams<any>) => (
        <div className="h-full flex justify-end items-center gap-4">
          <img
            src={CopySvg}
            alt="Copy"
            onClick={() =>
              copyPlan({plan: params.row, trainer_id: selectedTrainerId})
            }
            className="size-5"
          />
          <img
            onClick={() => handlePlanNavigation(params.row.id)}
            src={EditSvg}
            alt="Edit"
            className="size-5"
          />
          <img
            src={BasketSvg}
            onClick={() => handleDelete(params.row.id)}
            alt="Delete"
            className="size-5"
          />
        </div>
      ),
    },
  ];
  const [searchQuery, setSearchQuery] = useState('');
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [tableColumns, setTableColumns] = useState(
    columns.map(col => col.field),
  );
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(8);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const debouncedSearch = useDebounce(searchQuery, 300);

  const {
    data: plans,
    isLoading: isLoadingPlans,
    isError: isPlanError,
  } = useQuery(
    ['nutritionistPlans', page, pageSize, debouncedSearch, selectedTrainerId],
    () => handleFetchPlans(page, pageSize, debouncedSearch, selectedTrainerId),
    {
      keepPreviousData: true,
      // onSuccess: data => {
      //   if (data.data.length === 0) {
      //     setImportedArticles(data);
      //   }
      // },
    },
  );

  const {
    mutateAsync: exportData,
    isLoading: isExporting,
    isError: isExportError,
  } = useMutation(handleExport, {
    onSuccess: data => {
      downloadBlob(data, 'plans_exported_file.csv');
    },
  });

  const {
    mutateAsync: copyPlan,
    isLoading: isCopying,
    isError: isCopyError,
  } = useMutation(handlePlanCopy, {
    onSuccess: data => {
      queryClient.invalidateQueries(['nutritionistPlans']);
      if (data) {
        alert('Plan copied successfully');
      }
    },
    onError: err => {
      console.log(err);
      alert('Error copying plan');
      return;
    },
  });

  const {
    mutateAsync: deletePlan,
    isLoading: isDeleting,
    isError: isDeleteError,
  } = useMutation(handlePlanDelete, {
    onSuccess: data => {
      queryClient.invalidateQueries(['nutritionistPlans']);
      if (data) {
        alert(data.message);
      }
    },
    onError: err => {
      alert('Error deleting plan');
      console.log(err);
    },
  });

  const handlePlanNavigation = (id: any) => {
    navigate(`/nutrition-plans/${id}`);
  };

  const handleDelete = (id: any) => {
    setDelPlanId(id);
    setIsModalOpen(true);
  };

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleToggle = (option: any) => {
    const field = option.field;
    if (tableColumns.includes(field)) {
      setTableColumns(prevState => prevState.filter(item => item !== field));
    } else {
      setTableColumns(prevState => [...prevState, field]);
    }
  };

  const dataTransformation = (data: any) => {
    if (!data) return;
    const transformedData = data.map((plan: any) => ({
      id: crypto.randomUUID(),
      plan_name: plan.name,
      plan_gender: plan.plan_gender?.split(/,\s*/),
      plan_notes: plan.plan_notes,
      plan_ages: [
        {
          min: plan.min_age,
          max: plan.max_age,
        },
      ],
      plan_tags: plan.plan_tags?.split(/,\s*/),
    }));
    return transformedData ?? [];
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files && files.length > 0) {
      const file = files[0];
      const reader = new FileReader();
      reader.onload = e => {
        const data = new Uint8Array(e.target?.result as ArrayBuffer);
        const workbook = XLSX.read(data, {type: 'array'});
        const worksheet = workbook.Sheets[workbook.SheetNames[0]];
        const jsonData = XLSX.utils.sheet_to_json(worksheet);

        if (!isValidData(jsonData)) {
          alert('Invalid file format');
          return;
        } else {
          setImportedArticles((prev: any) => ({
            ...prev,
            data: dataTransformation(jsonData),
          }));
        }
      };
      reader.readAsArrayBuffer(file);
    }
  };

  if (isCopyError || isDeleteError || isExportError || isPlanError) {
    return <div>Error</div>;
  }
  if (isCopying || isDeleting || isLoadingPlans) {
    return <Loader />;
  }
  return (
    <div className="bg-light-green h-full p-8">
      <Header
        title={t('nutritionistplans')}
        subtitle={t('nutritionistsubheadings')}
        firstBtnTxt={t('import')}
        firstBtnIcon={ImportSvg}
        secondBtnTxt={t('export')}
        secondBtnIcon={ExportSvg}
        handleSubmitSecond={
          () => exportData({route: routes.EXPORT_NUTRITION_PLANS})
          // () => console.log('export')
        }
        handleSubmit={() => document.getElementById('xlsxUploadInput')?.click()}
        secondBtnLoading={isExporting}
      />
      <input
        id="xlsxUploadInput"
        type="file"
        accept=".xlsx,.xls,.csv"
        onChange={handleFileChange}
        style={{display: 'none'}}
      />
      <div className="flex items-center justify-end mt-8">
        <div className="flex gap-4">
          {localStorage.getItem('user_type') === '1' && (
            <TrainerSelector
              selectedTrainerId={selectedTrainerId}
              setSelectedTrainerId={setSelectedTrainerId}
            />
          )}
          <IconInput
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
          />
          <div
            onClick={handleClick}
            className="flex items-center gap-2 hover:cursor-pointer">
            <img src={ColSelectSvg} alt="sort" className="size-4" />
            <p className="text-dark-text text-[16px]">{t('selectcol')}</p>
          </div>
          <div>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleClose}>
              {columns.map((option: any) => (
                <MenuItem key={option.field}>
                  <Checkbox
                    checked={tableColumns.includes(option.field)}
                    onChange={() => handleToggle(option)}
                  />
                  <ListItemText primary={option.headerName} />
                </MenuItem>
              ))}
            </Menu>
          </div>
        </div>
      </div>
      {/* table */}
      <div className="mt-4 h-[calc(100%-160px)]">
        <NutrisionistTable
          columns={columns.filter(col => tableColumns.includes(col.field))}
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          deletePlan={deletePlan}
          delPlanId={delPlanId}
          page={page}
          setPage={setPage}
          pageSize={pageSize}
          setPageSize={setPageSize}
          plans={importedArticles ?? plans}
        />
      </div>
    </div>
  );
}

export default Index;
