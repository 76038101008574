import React, {useState} from 'react';
import {NavLink} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import People from '../assets/people.png';
import darkPeopleAdd from '../assets/peopleAdd.png';
import darkEgg from '../assets/egg.png';
import darkEggAdd from '../assets/eggAdd.png';
import darkJournal from '../assets/journal.png';
import darkJournalAdd from '../assets/journalAdd.png';
import darkLogout from '../assets/logout.png';
import darkPeople from '../assets/darkPeople.png';
import lightPeopleAdd from '../assets/lightPeopleAdd.png';
import lightEgg from '../assets/lightEgg.png';
import lightEggAdd from '../assets/lightEggAdd.png';
import lightJournal from '../assets/lightJournal.png';
import lightJournalAdd from '../assets/lightJournalAdd.png';
import DashboardIcon from '../assets/dashboardIcon.png';
import DashboardIconLight from '../assets/dashboardIconLight.png';
import IsraelFlag from '../assets/israelFlag.png';
import EnglishFlag from '../assets/englishFlag.png';
type Props = {
  onLogout: () => void;
};

function Navbar({onLogout}: Props) {
  const {i18n, t} = useTranslation();
  const [isPrivate, setIsPrivate] = useState<Boolean>(
    localStorage.getItem('user_type') === '1' ? true : false,
  );
  const navbarOptions = [
    {
      id: 8,
      label: t('dashboard'),
      path: '/',
      darkIcon: DashboardIcon,
      isShow: true,
      lightIcon: DashboardIconLight,
      key: '8',
    },
    {
      id: 1,
      label: t('clients'),
      path: '/clients',
      darkIcon: darkPeople,
      isShow: true,
      lightIcon: People,
      key: '1',
    },
    {
      id: 2,
      label: t('addnewclient'),
      path: '/addNewClient',
      darkIcon: darkPeopleAdd,
      isShow: true,
      lightIcon: lightPeopleAdd,
      key: '2',
    },
    {
      id: 3,
      label: t('nutritionistplans'),
      path: '/nutritionistPlans',
      darkIcon: darkEgg,
      isShow: true,
      lightIcon: lightEgg,
      key: '3',
    },
    {
      id: 4,
      label: t('addnewplan'),
      path: '/nutrition-plans',
      darkIcon: darkEggAdd,
      isShow: true,
      lightIcon: lightEggAdd,
      key: '4',
    },
    {
      id: 5,
      label: t('articles'),
      path: '/articles',
      darkIcon: darkJournal,
      isShow: true,
      lightIcon: lightJournal,
      key: '5',
    },
    {
      id: 6,
      label: t('addnewarticle'),
      path: '/addNewArticle',
      darkIcon: darkJournalAdd,
      isShow: true,
      lightIcon: lightJournalAdd,
      key: '6',
    },
    {
      id: 9,
      label: t('trainers'),
      path: '/trainers',
      darkIcon: darkPeople,
      isShow: isPrivate,
      lightIcon: People,
      key: '9',
    },
    {
      id: 10,
      label: t('addNewTrainer'),
      path: '/addNewTrainer',
      darkIcon: darkPeopleAdd,
      isShow: isPrivate,
      lightIcon: lightPeopleAdd,
      key: '10',
    },
    {
      id: 13,
      label: t('foodValues'),
      path: '/foodList',
      darkIcon: darkJournalAdd,
      isShow: isPrivate,
      lightIcon: lightJournalAdd,
      key: '13',
    },
    {
      id: 11,
      label: t('addNewFood'),
      path: '/addNewFood',
      darkIcon: darkPeopleAdd,
      isShow: isPrivate,
      lightIcon: lightPeopleAdd,
      key: '11',
    },
    {
      id: 14,
      label: t('recipes'),
      path: '/recipes',
      darkIcon: darkJournalAdd,
      isShow: isPrivate,
      lightIcon: lightJournalAdd,
      key: '14',
    },
    {
      id: 12,
      label: t('addNewRecipe'),
      path: '/addNewRecipe',
      darkIcon: darkPeopleAdd,
      isShow: isPrivate,
      lightIcon: lightPeopleAdd,
      key: '12',
    },
  ];
  const changeLanguage = (lng: string) => {
    localStorage.setItem('currentLang', lng);
    i18n.changeLanguage(lng);
    document.documentElement.dir = lng === 'he' ? 'rtl' : 'ltr';
  };

  return (
    <div className="flex flex-col gap-4 px-4 mt-8">
      {navbarOptions?.map(
        option =>
          option.isShow && (
            <NavLink
              key={option.key}
              to={option.path}
              className={({isActive}) =>
                `flex items-center gap-3 py-1 rounded-lg px-4 hover:cursor-pointer ${
                  isActive ? 'text-dark-text' : 'text-gray-text'
                }`
              }>
              <NavLink
                to={option.path}
                className={({isActive}) =>
                  `rounded-full flex items-center justify-center p-2 ${
                    isActive ? 'bg-black' : ''
                  }`
                }>
                {/* <img
              src={option.lightIcon}
              alt={option.label}
              className="size-[25px]"
            /> */}
                <img
                  src={option.darkIcon}
                  alt={option.label}
                  className="size-[25px]"
                />
              </NavLink>
              <span className="text-[16px]">{option.label}</span>
            </NavLink>
          ),
      )}
      {/* logout btn */}
      <button
        onClick={onLogout}
        className="flex items-center gap-3 py-1 rounded-lg px-4 hover:cursor-pointer text-gray-text">
        <div className="rounded-full flex items-center justify-center p-2 ">
          <img src={darkLogout} alt="dark-logout" className="size-[25px]" />
        </div>
        <span className="text-[16px]">{t('logout')}</span>
      </button>
      <div className="flex flex-col gap-3 items-start px-4 mt-1 mb-5">
        {i18n.language === 'he' ? (
          <button
            className="flex items-center gap-3 border px-4 py-1 border-border-color rounded-full"
            onClick={() => changeLanguage('en')}>
            <img
              className="size-[20px] rounded-full"
              src={EnglishFlag}
              alt="English"
            />
            English
          </button>
        ) : (
          <button
            className="flex items-center gap-3 border px-4 py-1 border-border-color rounded-full"
            onClick={() => changeLanguage('he')}>
            {' '}
            <img
              className="size-[20px] rounded-full"
              src={IsraelFlag}
              alt="Israel"
            />{' '}
            Hebrew
          </button>
        )}
      </div>
    </div>
  );
}

export default Navbar;
