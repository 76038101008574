import React, {useRef} from 'react';
import {useTranslation} from 'react-i18next';
import Camera from '../../assets/camera.png';
import ReactQuill from 'react-quill';
import TrainerSelector from '../../components/TrainerSelector';

type Props = {
  client: any;
  setClient: (client: any) => void;
};

function AddFoods({client, setClient}: Props) {
  const {t} = useTranslation();
  const inputImgRef = useRef<HTMLInputElement>(null);

  const column1 = [
    {id: 2, key: 'title', label: t('recepieTitle'), type: 'text'},
    {id: 3, key: 'calories_per100g', label: t('caloriesPer'), type: 'number'},
    {id: 4, key: 'carbs_per100g', label: t('carbsPer'), type: 'number'},
    {id: 5, key: 'fat_per100g', label: t('fatPer'), type: 'number'},
    {id: 6, key: 'protein_per100g', label: t('proteinPer'), type: 'number'},
  ];

  const handleChange = (key: string, value: any) => {
    setClient((prev: any) => ({
      ...prev,
      [key]: value,
    }));
  };

  const imgSelector = () => {
    inputImgRef.current?.click();
  };

  const handleImg = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file) return;

    if (file.size > 300 * 1024) {
      alert('File size should be less than 300KB');
      return;
    }

    const reader = new FileReader();
    reader.onload = () => {
      setClient((prev: any) => ({
        ...prev,
        image: reader.result,
      }));
    };
    reader.onerror = error => console.error('Error reading file:', error);
    reader.readAsDataURL(file);
  };
  const modules = {
    toolbar: [
      ['bold', 'italic', 'underline'],
      [{align: []}],
      [{list: 'ordered'}],
      [{direction: 'rtl'}],
      ['link', 'image'],
      ['emoji'],
    ],
  };

  const formats = [
    'bold',
    'italic',
    'underline',
    'align',
    'list',
    'direction',
    'link',
    'image',
    'emoji',
  ];

  return (
    <div className="border border-border-color rounded-[15px] h-[calc(100%-90px)] overflow-auto grid grid-cols-1 mt-4 p-6">
      <div className="flex flex-row gap-12 w-full">
        {/* Column 1 - 25% width */}
        <div className="w-[30%]">
          <p className="text-[20px] font-medium mb-2">{t('foodDetails')}</p>
          {column1.map(item => (
            <div className="mt-6" key={item.id}>
              <div className="mt-4">
                <p className="text-xs font-[400] text-dark-text leading-4 mb-1">
                  {item.label}
                </p>
                <input
                  type={item.type}
                  name={item.key}
                  value={client[item.key]}
                  onChange={e => handleChange(item.key, e.target.value)}
                  className="w-full h-[40px] rounded-[40px] border-[1px] border-border-color px-4 text-xs focus:outline-none focus:ring-1 focus:ring-[#7ECD93] focus:border-[#7ECD93]"
                />
              </div>
            </div>
          ))}
          {/* <div className="mt-4">
            <p className="text-xs font-[400] text-dark-text mb-1">
              {t('trainers')}
            </p>
            <TrainerSelector
              selectedTrainerId={selectedTrainerId}
              setSelectedTrainerId={setSelectedTrainerId}
              width={'100%'}
              isShowLabel={false}
            />
          </div> */}
          <div className="w-fit mt-4">
            <p className="text-xs font-[400] text-dark-text leading-4 mb-4">
              {t('recipePhoto')}
            </p>
            {client.image ? (
              <div
                onClick={imgSelector}
                className="h-[80px] w-[80px] overflow-hidden rounded-full flex items-center justify-center cursor-pointer">
                <img
                  src={client.image}
                  alt="profile-pic"
                  className="h-full w-full object-cover"
                />
              </div>
            ) : (
              <div
                onClick={imgSelector}
                className="min-h-[80px] min-w-[80px] w-fit bg-gray-600 rounded-full flex items-center flex-col justify-center cursor-pointer">
                <img src={Camera} alt="camera" />
                <p className="text-xs font-[400] mt-1 text-white">
                  {t('photo')}
                </p>
              </div>
            )}
            <input
              ref={inputImgRef}
              className="hidden"
              type="file"
              onChange={handleImg}
              accept=".png, .jpg, .jpeg"
            />
          </div>
        </div>

        {/* Column 2 - 75% width */}
        <div className="w-[70%] mt-12">
          <p className="text-xs font-[400] text-dark-text leading-4 mb-2">
            {t('howtomake')}
          </p>
          <ReactQuill
            theme="snow"
            placeholder="Enter content"
            modules={modules}
            formats={formats}
            style={{
              backgroundColor: 'white',
            }}
            value={client.how_to ?? ''}
            onChange={value => handleChange('how_to', value)}
            className="w-[95%]  rounded-[12px]  border-[1px] border-border-color px-1 py-1 text-md focus:outline-none focus:ring-1 focus:ring-[#7ECD93] focus:border-[#7ECD93]"
          />
          <p className="text-xs font-[400] text-dark-text leading-4 mb-2 pt-6">
            {t('ingredients')}
          </p>
          <ReactQuill
            theme="snow"
            placeholder="Enter content"
            modules={modules}
            formats={formats}
            style={{
              backgroundColor: 'white',
            }}
            value={client.ingredients ?? ''}
            onChange={value => handleChange('ingredients', value)}
            className="w-[95%]  rounded-[12px]  border-[1px] border-border-color px-1 py-1 text-md focus:outline-none focus:ring-1 focus:ring-[#7ECD93] focus:border-[#7ECD93]"
          />
        </div>
      </div>
    </div>
  );
}

export default AddFoods;
