import {Article} from '../../types';
import {api} from '../axiosInstance';

export const handleAddArticle = async ({article, id}: any) => {
  // const {article_title, article_content, article_photo} = article;
  // if (!article_title || !article_content || !article_photo) {
  //   return alert('All fields are required');
  // }
  const response = await api.post('/articles', {
    articles: [{...article}],
    trainer_id: id ?? null,
  });
  return response.data;
};

export const handleEditArticle = async ({article, id}: any) => {
  const {article_title, article_content, article_photo} = article;
  if (!article_title || !article_content || !article_photo) {
    return alert('All fields are required');
  }
  const response = await api.put(`/articles/${id}`, {
    ...article,
  });
  return response.data;
};

export const handleArticleData = async (articleId: any) => {
  const response = await api.get(`/articles/${articleId}`);
  return response.data;
};

export const handleFetchArticles = async (
  search: string = '',
  trainer_id: number | string | null = null,
) => {
  const response = await api.get(
    `/articles?search=${search}&trainer_id=${
      trainer_id !== null ? trainer_id : ''
    }`,
  );
  return response.data;
};
