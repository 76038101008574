import { routes } from "../../constants/routes";
import { api } from "../axiosInstance";

export const handleFetchPlans = async (
  page: number,
  pageSize: number,
  debouncedSearch: string,
  selectedTrainerId: number | string | null = null
) => {
  const response = await api.get(
    `${
      routes.NUTRITION_PLANS
    }?page=${page}&limit=${pageSize}&search=${debouncedSearch}&sortKey=plan_tags&sortDirection=DESC&trainer_id=${
      selectedTrainerId !== null ? Number(selectedTrainerId) : ""
    }`
  );
  return response.data;
};

export const handlePlanCopy = async ({ plan, trainer_id }: any) => {
  const copiedPlan = {
    dietary_restrictions: plan.dietary_restrictions || "",
    eating_times: plan.eating_times || [],
    plan_ages: plan.plan_ages || [],
    plan_gender: plan.plan_gender || [],
    plan_name: plan.plan_name || "",
    plan_notes: plan.plan_notes || "",
    plan_type: plan.plan_type || "",
    plan_tags: plan.plan_tags || [],
  };
  const response = await api.post(`${routes.NUTRITION_PLANS}`, {
    nutritionPlans: [{ ...copiedPlan }],
    trainer_id: trainer_id ? Number(trainer_id) : null,
  });
  return response.data;
};

export const handlePlanDelete = async (planId: any) => {
  const response = await api.delete(
    `${routes.NUTRITION_PLANS}/${Number(planId)}`
  );
  return response.data;
};

export const handlePlansAdding = async ({ plan, trainer_id }: any) => {
  console.log("from inner reuest", plan, trainer_id);
  // const {plan_name, plan_gender, plan_ages, plan_tags} = plan;
  // if (
  //   plan_name === '' ||
  //   plan_gender.length === 0 ||
  //   plan_ages.length === 0 ||
  //   plan_tags.length === 0
  // ) {
  //   return alert('Please fill all the required fields');
  // }
  const response = await api.post(`${routes.NUTRITION_PLANS}`, {
    nutritionPlans: [{ ...plan }],
    trainer_id: trainer_id ? Number(trainer_id) : null,
  });
  return response.data;
};

export const handleGetPlan = async (planId: any) => {
  const response = await api.get(`${routes.NUTRITION_PLANS}/${planId}`);
  return response.data;
};

export const handleEditPlan = async ({ plan, planId }: any) => {
  const { plan_name, plan_gender, plan_ages, plan_tags } = plan;
  if (
    plan_name === "" ||
    plan_gender.length === 0 ||
    plan_ages.length === 0 ||
    plan_tags.length === 0 ||
    planId === ""
  ) {
    return alert("Please fill all the required fields");
  }
  const response = await api.put(`${routes.NUTRITION_PLANS}/${planId}`, {
    ...plan,
  });
  return response.data;
};
