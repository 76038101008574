import React, { useEffect, useState } from "react";
import Header from "../../components/Header";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import {
  handleFoodIngredientAdding,
  handleGetFoodItem,
  handleEditFoodItem,
} from "../../services/foodIngredients";
import AddFoods from "./addFoods";

function Index() {
  const { t } = useTranslation();
  const { userId } = useParams();
  const navigate = useNavigate();

  const queryClient = useQueryClient();
  // const [selectedTrainerId, setSelectedTrainerId] = useState<any>(null);
  const [foodIngredient, setFoodIngredient] = useState({
    item_name: "",
    item_calories_per100g: "",
    item_carbs_per100g: "",
    item_fat_per100g: "",
    item_protein_per100g: "",
  });

  const {
    data: editFoodItem,
    error: fetchError,
    isLoading: isFetching,
  } = useQuery(["foodItem", userId], () => handleGetFoodItem(Number(userId)), {
    enabled: !!userId,
    onSuccess: (data) => {
      console.log("edited successfully");
      queryClient.invalidateQueries(["foodItems"]);
    },
  });

  useEffect(() => {
    if (editFoodItem) {
      setFoodIngredient({
        item_name: editFoodItem.item_name || "",
        item_calories_per100g: editFoodItem.item_calories_per100g || "",
        item_carbs_per100g: editFoodItem.item_carbs_per100g || "",
        item_fat_per100g: editFoodItem.item_fat_per100g || "",
        item_protein_per100g: editFoodItem.item_protein_per100g || "",
      });
    }
  }, [editFoodItem]);

  const {
    mutateAsync: addIngredients,
    isLoading: isAdding,
    isError: isAddError,
  } = useMutation(handleFoodIngredientAdding, {
    onSuccess: () => {
      setFoodIngredient({
        item_name: "",
        item_calories_per100g: "",
        item_carbs_per100g: "",
        item_fat_per100g: "",
        item_protein_per100g: "",
      });
      // setSelectedTrainerId(null);
      console.log("Food Ingredient added successfully");
    },
    onError: (error) => {
      console.error("Error adding food ingredient:", error);
    },
  });

  const {
    mutateAsync: editTrainerData,
    isLoading: isEditing,
    isError: isEditError,
  } = useMutation(handleEditFoodItem, {
    onSuccess: () => {
      console.log("Food Item edited successfully");
      navigate(-1);
    },
    onError: (error) => {
      console.error("Error editing food Item:", error);
    },
  });

  const handleSubmit = async () => {
    if (userId) {
      await editTrainerData({ foodIngredient, foodId: userId });
    } else {
      // if (!selectedTrainerId) {
      //   return alert("please select trainer");
      // }
      const {
        item_name,
        item_calories_per100g,
        item_fat_per100g,
        item_protein_per100g,
      } = foodIngredient;
      if (
        item_name === "" ||
        item_calories_per100g === "" ||
        item_fat_per100g === "" ||
        item_protein_per100g === ""
      ) {
        return alert("Please fill all the required fields");
      }
      await addIngredients({
        foodIngredient,
        //  trainer_id: selectedTrainerId
      });
    }
  };

  if (fetchError || isAddError || isEditError) {
    return <div>Error while fetching and adding/editing food Item</div>;
  }

  return (
    <div className="bg-light-green h-full p-8">
      <Header
        title={t("addEditFood")}
        subtitle={t("addclientsubheading")}
        firstBtnTxt={t("save")}
        handleSubmit={handleSubmit}
        firstBtnLoading={isAdding || isEditing || isFetching}
      />
      <AddFoods
        foodIngredient={foodIngredient}
        setFoodIngredient={setFoodIngredient}
        // selectedTrainerId={selectedTrainerId}
        // setSelectedTrainerId={setSelectedTrainerId}
      />
    </div>
  );
}

export default Index;
