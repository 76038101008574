import React, {useEffect, useState} from 'react';
import Header from '../../components/Header';
import {useTranslation} from 'react-i18next';
import {useMutation, useQuery, useQueryClient} from 'react-query';
import {useNavigate, useParams} from 'react-router-dom';
import {
  handleRecipeAdding,
  handleGetRecipe,
  handleEditRecipe,
} from '../../services/foodRecipies';
import AddFoods from './addFoods';
import {Recipe} from '../../types';
function Index() {
  const {t} = useTranslation();
  const {userId} = useParams();
  const navigate = useNavigate();

  const queryClient = useQueryClient();
  // const [selectedTrainerId, setSelectedTrainerId] = useState<any>(null);
  const [recipe, setRecipe] = useState<Recipe>({
    title: '',
    image: '',
    calories_per100g: null,
    carbs_per100g: null,
    fat_per100g: null,
    protein_per100g: null,
    how_to: '',
    ingredients: '',
  });

  const {
    data: editRecipe,
    error: fetchError,
    isLoading: isFetching,
  } = useQuery(['recipe', userId], () => handleGetRecipe(Number(userId)), {
    enabled: !!userId,
    onSuccess: data => {
      console.log('edited successfully');
      queryClient.invalidateQueries(['recipes']);
    },
  });

  useEffect(() => {
    if (editRecipe) {
      setRecipe({
        title: editRecipe.title || '',
        image: editRecipe.image || '',
        calories_per100g: editRecipe.calories_per100g || '',
        carbs_per100g: editRecipe.carbs_per100g || '',
        fat_per100g: editRecipe.fat_per100g || '',
        protein_per100g: editRecipe.protein_per100g || '',
        how_to: editRecipe.how_to || '',
        ingredients: editRecipe.ingredients || '',
      });
    }
  }, [editRecipe]);

  const {
    mutateAsync: addRecipe,
    isLoading: isAdding,
    isError: isAddError,
  } = useMutation(handleRecipeAdding, {
    onSuccess: () => {
      setRecipe({
        title: '',
        image: '',
        carbs_per100g: null,
        fat_per100g: null,
        protein_per100g: null,
        calories_per100g: null,
        how_to: '',
        ingredients: '',
      });
      console.log('Recipe added successfully');
    },
    onError: error => {
      console.error('Error adding recipe:', error);
    },
  });

  const {
    mutateAsync: editRecipeData,
    isLoading: isEditing,
    isError: isEditError,
  } = useMutation(handleEditRecipe, {
    onSuccess: () => {
      console.log('Recipe edited successfully');
      navigate(-1);
    },
    onError: error => {
      console.error('Error editing Recipe:', error);
    },
  });

  const handleSubmit = async () => {
    if (userId) {
      await editRecipeData({recipe, recipeId: userId});
    } else {
      const {
        title,
        image,
        calories_per100g,
        carbs_per100g,
        fat_per100g,
        protein_per100g,
        how_to,
        ingredients,
      } = recipe;
      if (
        title === '' ||
        image === '' ||
        calories_per100g === null ||
        carbs_per100g === null ||
        fat_per100g === null ||
        protein_per100g === null ||
        how_to === '' ||
        ingredients === ''
      ) {
        return alert('Please fill all the required fields');
      }
      await addRecipe({recipe});
    }
  };

  if (fetchError || isAddError || isEditError) {
    return <div>Error while fetching and adding/editing trainer</div>;
  }

  console.log('recepie data is =====>', recipe);

  return (
    <div className="bg-light-green h-full p-8">
      <Header
        title={t('addEditRecipe')}
        subtitle={t('addclientsubheading')}
        firstBtnTxt={t('save')}
        handleSubmit={handleSubmit}
        firstBtnLoading={isAdding || isEditing}
      />
      <AddFoods client={recipe} setClient={setRecipe} />
    </div>
  );
}

export default Index;
