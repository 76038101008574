import React, { useEffect, useRef, useState } from "react";
import Header from "../../components/Header";
import Camera from "../../assets/camera.png";
import { Article } from "../../types";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import {
  handleAddArticle,
  handleArticleData,
  handleEditArticle,
} from "../../services/articles";
import Loader from "../../components/Loader";
import TrainerSelector from "../../components/TrainerSelector";
type Props = {};
function Index({}: Props) {
  const { articleId } = useParams();
  const navigate = useNavigate();

  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const inputImgRef = useRef(null);
  const [article, setArticle] = useState<Article>({
    article_title: "",
    article_content: "",
    article_photo: "",
  });
  const [selectedTrainerId, setSelectedTrainerId] = useState<any>(null);
  const {
    mutateAsync: addArticle,
    isLoading: isAddLoading,
    isError: isAddError,
  } = useMutation(handleAddArticle, {
    onSuccess: (data) => {
      setArticle({ article_title: "", article_content: "", article_photo: "" });
      setSelectedTrainerId(null);
    },
    onError: (err) => {
      console.log(err);
      alert("Error adding article");
    },
  });

  const {
    mutateAsync: editArticle,
    isLoading: isEditLoading,
    isError: isEditError,
  } = useMutation(handleEditArticle, {
    onSuccess: (data) => {
      setArticle({ article_title: "", article_content: "", article_photo: "" });
      if (data.data) alert("Article edited successfully");
      navigate(-1);
    },
    onError: (err) => {
      console.log(err);
      alert("Error editing article");
    },
  });

  const {
    data: articleData,
    isError: isArticleError,
    isLoading: isArticleLoading,
  } = useQuery(["article", articleId], () => handleArticleData(articleId), {
    enabled: !!articleId,
    onSuccess: (data) => {
      queryClient.invalidateQueries(["articles"]);
    },
    onError: (err) => {
      console.log(err);
    },
  });

  useEffect(() => {
    if (!articleData) return;
    setArticle({
      article_title: articleData.article_title ?? "",
      article_content: articleData.article_content ?? "",
      article_photo: articleData.article_photo ?? "",
    });
    setSelectedTrainerId(articleData.created_by?.id ?? null);
  }, [articleData]);

  const handleImg = (event: React.ChangeEvent<HTMLInputElement>) => {
    // @ts-ignore
    const file = event.target.files[0];
    if (!file || file.size > 300 * 1024) {
      return;
    }
    const reader = new FileReader();
    reader.onload = () => {
      const base64String = reader.result as string;
      setArticle((prev: any) => ({
        ...prev,
        article_photo: base64String,
      }));
    };
    reader.readAsDataURL(file);
  };

  const imgSelector = () => {
    // @ts-ignore
    if (inputImgRef.current) inputImgRef.current?.click();
  };
  const handleChange = (key: string, value: string) => {
    setArticle((prev: any) => {
      return {
        ...prev,
        [key]: value,
      };
    });
  };
  const handleSubmit = async () => {
    try {
      if (articleId) {
        await editArticle({
          article,
          id: articleId,
        });
      } else {
        if (!selectedTrainerId && localStorage.getItem("user_type") === "1")
          return alert("please select trainer");
        const { article_title, article_content, article_photo } = article;
        if (!article_title || !article_content || !article_photo) {
          return alert("All fields are required");
        }
        await addArticle({ article, id: selectedTrainerId });
      }
    } catch (error) {
      console.log("Submission error:", error);
    }
  };
  if (isAddError || isEditError || isArticleError) {
    return <div>Error occurred while fetching data</div>;
  }

  if (isArticleLoading) return <Loader />;

  const modules = {
    toolbar: [
      ["bold", "italic", "underline"],
      [{ align: [] }],
      [{ list: "ordered" }],
      [{ direction: "rtl" }],
      ["link", "image"],
      ["emoji"],
    ],
  };

  const formats = [
    "bold",
    "italic",
    "underline",
    "align",
    "list",
    "direction",
    "link",
    "image",
    "emoji",
  ];

  return (
    <div className="bg-light-green h-full p-8">
      <Header
        title={t("articleedit")}
        subtitle={t("articlesubheading")}
        firstBtnTxt={t("save")}
        handleSubmit={handleSubmit}
        firstBtnLoading={isAddLoading || isEditLoading}
      />

      <div className="border border-border-color rounded-[15px] mt-4 p-6 h-[calc(100%-90px)] overflow-auto grid grid-cols-12 gap-2 ">
        <div className="col-span-9 p-4">
          <p className="text-[20px] font-medium mb-2">{t("articletitle")}</p>
          <input
            type="text"
            name="article_title"
            value={article?.article_title ?? ""}
            onChange={(e) => handleChange("article_title", e.target.value)}
            className="w-full h-[80px] rounded-[12px]  border-[1px] border-border-color px-4 text-md focus:outline-none focus:ring-1 focus:ring-[#7ECD93] focus:border-[#7ECD93]"
          />
          <p className="text-[20px] font-medium mt-4 mb-2">{t("content")}</p>
          <ReactQuill
            theme="snow"
            placeholder="Enter content"
            style={{
              backgroundColor: "white",
            }}
            modules={modules}
            formats={formats}
            value={article.article_content ?? ""}
            onChange={(value) => handleChange("article_content", value)}
            className="w-full  rounded-[12px]  border-[1px] border-border-color px-1 py-1 text-md focus:outline-none focus:ring-1 focus:ring-[#7ECD93] focus:border-[#7ECD93]"
          />
        </div>
        <div className="col-span-3 p-4 flex flex-col gap-8">
          {localStorage.getItem("user_type") === "1" && (
            <TrainerSelector
              selectedTrainerId={selectedTrainerId}
              setSelectedTrainerId={setSelectedTrainerId}
              width={"100%"}
              borderRadius="8px"
            />
          )}
          <div
            onClick={imgSelector}
            className="bg-imgHolder-bg h-[350px] w-full overflow-hidden flex items-center justify-center rounded-[8px]"
          >
            {article.article_photo ? (
              <img
                src={article.article_photo}
                alt="img"
                className="w-full h-full object-cover"
              />
            ) : (
              <div className="h-full w-full flex items-center justify-center">
                <div className="size-[80px] flex items-center justify-center rounded-full bg-gray-500">
                  <img src={Camera} alt="camera" className="size-[14px]" />
                </div>
              </div>
            )}
          </div>
          <input
            ref={inputImgRef}
            className="hidden"
            type="file"
            onChange={handleImg}
            accept=".png, .jpg, .jpeg"
          />
        </div>
      </div>
    </div>
  );
}

export default Index;
